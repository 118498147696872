import { useAction } from "@helium10/re-core";
import type { IAccountId } from "@walmart10/features/src/common/types/IAccountId";
import { useCallback } from "react";
import { generatePath } from "react-router-dom";

import { clearProfileAction } from "@/store/account/profileStore";

import { useRedirect } from "./useRedirect";

const dashboardRoute = "/advertising" + "/panel/:accountId" + "/dashboard";
const scheduleRuleBuilderCreateRoute =
  "/advertising" + "/panel/:accountId" + "/schedule-rule" + "/builder/create";

const getRedirectDashboardRoute = (accountId: string) =>
  generatePath(dashboardRoute, { accountId });

export const getSchedulesBuilderRoute = (accountId: string) =>
  generatePath(scheduleRuleBuilderCreateRoute, { accountId });

export const useAccountChange = () => {
  const { redirect } = useRedirect();
  const clearProfile = useAction(clearProfileAction);

  const handleAccountChange = useCallback(
    (accountId: IAccountId) => {
      if (window.location.href.includes("/advertising")) {
        clearProfile();
        if (window.location.href.includes("schedules/builder")) {
          redirect(getSchedulesBuilderRoute(accountId.toString()), { accountId: accountId });
          // window.location.reload();
        } else {
          redirect(getRedirectDashboardRoute(accountId.toString()), {
            accountId: accountId,
          });
        }
      }
    },
    [redirect, clearProfile],
  );

  return handleAccountChange;
};
