import {
  getUrlWithSearchParams,
  isDevHost,
  isMainAppDevOrCi,
  transformHelium10Domain,
  useAccountPlans,
  useCredentials,
  useQuery,
} from "@helium10/re-core";
import { IAccountId } from "@walmart10/features/src/common/types/IAccountId";
import { useWithOutCredentialList } from "@walmart10/userSystem";
import { lazy, memo, Suspense, useCallback, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useAccountChange as useAdtomicAccountChange } from "@/core/hooks/useAccountChange";

import { useRedirect } from "./../src/core/hooks/useRedirect";
import { checkExtension } from "./checkExtension";

const NewMenu = lazy(() =>
  import(
    /* webpackChunkName: "NewMenu" */ "@walmart10/features/src/components/newMenu/NewMenu"
  ).then((module) => ({
    default: module.NewMenu,
  })),
);

const CerebroRoutes = lazy(() =>
  import(/* webpackChunkName: "CerebroRoutes" */ "./tools/research/src/App").then((module) => ({
    default: module.CerebroRoutes,
  })),
);
const MagnetRoutes = lazy(() =>
  import(/* webpackChunkName: "MagnetRoutes" */ "./tools/research/src/App").then((module) => ({
    default: module.MagnetRoutes,
  })),
);
const KeywordTrackerRoutes = lazy(() =>
  import(/* webpackChunkName: "KeywordTrackerRoutes" */ "./tools/research/src/App").then(
    (module) => ({
      default: module.KeywordTrackerRoutes,
    }),
  ),
);

const Profits = lazy(() =>
  import(/* webpackChunkName: "Profits" */ "./tools/profits/src/App").then((module) => ({
    default: module.Profits,
  })),
);

const UserSystem = lazy(() =>
  import(/* webpackChunkName: "UserSystem" */ "./tools/userSystem/App").then((module) => ({
    default: module.UserSystem,
  })),
);

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Modals" */ "./tools/dashboard/App").then((module) => ({
    default: module.Dashboard,
  })),
);

const Adtomic = lazy(() =>
  import(/* webpackChunkName: "Adtomic" */ "./tools/adtomic/App").then((module) => ({
    default: module.Adtomic,
  })),
);

const TokensManager = lazy(() =>
  import(/* webpackChunkName: "TokensManager" */ "@helium10/re-miscellaneous").then((module) => ({
    default: module.TokensManager,
  })),
);

const ConnectionFlow = lazy(() =>
  import(/* webpackChunkName: "ConnectionFlow" */ "@helium10/re-miscellaneous").then((module) => ({
    default: module.ConnectionFlow,
  })),
);

const Maintenance = lazy(() =>
  import(/* webpackChunkName: "Maintenance" */ "./tools/maintenance/App").then((module) => ({
    default: module.Maintenance,
  })),
);

const StyledConnectionFlow = () => (
  <ConnectionFlowWrapper>
    <ConnectionFlow />
  </ConnectionFlowWrapper>
);

const LayoutRoutes = memo(() => {
  const { accountId } = useQuery<{ accountId: string }>();
  const token = useCredentials(accountId);
  const handleAdtomicAccountChange = useAdtomicAccountChange();
  useAccountPlans({ accountId });

  useEffect(() => {
    checkExtension(accountId);
  }, [accountId]);

  const onAccountChange = useCallback((id: IAccountId) => {
    if (window.location.href.includes("/advertising")) {
      handleAdtomicAccountChange(id);
      return;
    }

    const walmarkLink = transformHelium10Domain(
      isMainAppDevOrCi ? window.location.origin : process.env.REACT_APP_WALMART_DOMAIN || "",
    );

    window.location.assign(
      getUrlWithSearchParams(
        isDevHost?.() ? window.location.origin : walmarkLink,
        { accountId: id },
        { hasAccountId: false },
      ),
    );
  }, []);

  return (
    <>
      <NewMenu
        accountId={accountId ? Number(accountId) : undefined}
        logoutToken={token}
        onAccountChange={onAccountChange}
      >
        <BasicRoutes />
      </NewMenu>
      <ToolTipsBlock id={"tooltipContainer"} />
    </>
  );
});

const removePrefix = (path: string) => {
  if (path.startsWith("/members")) {
    return path.replace("/members", "");
  }
  return path;
};

const BasicRoutes = memo(() => {
  const { getPath } = useRedirect();

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={`/dashboard`} element={<Dashboard />} />
        <Route path={`/cerebro/*`} element={<CerebroRoutes />} />
        <Route path={`/magnet/*`} element={<MagnetRoutes />} />
        <Route path={"/profits/*"} element={<Profits />} />
        <Route path={"/advertising/*"} element={<Adtomic />} />
        <Route
          path={"/new-account/tokens-manager-list/*"}
          element={<TokensManager newConnection />}
        />
        <Route path={`/new-account/tokens-manager/add/*`} element={<StyledConnectionFlow />} />
        <Route path={"/new-user-sys/*"} element={<UserSystem />} />
        <Route path={"/keyword-tracker-beta/*"} element={<KeywordTrackerRoutes />} />
        <Route path={"/maintenance"} element={<Maintenance />} />
        <Route path={`/*`} element={<Navigate to={getPath("/dashboard")} replace />} />
      </Routes>
    </Suspense>
  );
});

const ProtectedRoute = () => {
  const location = useLocation();
  const list = useWithOutCredentialList();
  const withoutCredential = list.map((path) => `/new-user-sys${path}`).includes(location.pathname);
  return withoutCredential ? <BasicRoutes /> : <LayoutRoutes />;
};

export const BaseRoutes = memo(() => {
  return (
    <Suspense fallback={null}>
      <Routes>
        {process.env.REACT_APP_STATE === "dev" && (
          <Route
            path={`/members/*`}
            element={<Navigate to={removePrefix(window.location.pathname)} replace />}
          />
        )}
        <Route path={`/*`} element={<ProtectedRoute />} />
      </Routes>
    </Suspense>
  );
});

const ConnectionFlowWrapper = styled.div`
  & > div {
    min-height: calc(100vh - 64px - 40px);
  }
`;

export const ToolTipsBlock = styled.div``;
